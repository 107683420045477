<template>
	<v-layout
		class="phone-text-field"
		:class="{
			'mt-3': !hideTopMargin,
			className: className,
		}"
	>
		<v-flex class="max-width-80px">
			<v-select
				outlined
				v-model="countryCode"
				:items="countryList"
				hide-details
				:disabled="disabled"
				:loading="loading"
				item-text="name"
				item-value="iso2"
				item-color="blue darken-4"
				return-object
				v-on:change="updateActiveCountry(phone)"
			>
				<template v-slot:selection>
					<div :class="activeCountry.iso2.toLowerCase()" class="vti__flag mr-2" />
				</template>
				<template v-slot:item="data">
					<span :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
					<span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
				</template>
			</v-select>
		</v-flex>
		<v-flex>
			<v-text-field
				:id="id"
				v-model="phone"
				outlined
				:prefix="getPrefix"
				:rules="rules"
				hide-details
				:disabled="disabled"
				:class="{
					required: required && !phone ? true : false,
				}"
				:loading="loading"
				type="number"
				hide-spin-buttons
				v-on:keypress="disableCharacter($event, phone)"
				:placeholder="placeholder"
				v-on:keyup="emitValue()"
				v-on:change="
					emitValue();
					validatePhoneNumber();
				"
			>
			</v-text-field>
			<span
				v-if="fieldNameError"
				v-html="fieldNameError"
				class="red--text text--darken-1 font-small"
			></span>
		</v-flex>
	</v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
//import SelectInput from "@/view/components/SelectInput.vue";
//import { trimStart } from "lodash";

import MainMixin from "@/core/mixins/main.mixin.js";
import allCountries from "@/core/plugins/country-list";
import { find, split, replace } from "lodash";
import { ValidateInputField } from "@/core/lib/common.lib";

export default {
	name: "text-phone-input",
	mixins: [MainMixin],
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: "phone",
		},
		className: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: "Phone Number",
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		parentId: {
			type: [String, Number],
			default: null,
		},
		currentId: {
			type: [String, Number],
			default: null,
		},
		validationField: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			phone: null,
			fieldNameError: null,
			inputvalue: null,
			countryCode: {
				areaCodes: null,
				dialCode: "65",
				iso2: "SG",
				name: "Singapore",
				priority: 0,
			},
			activeCountry: {
				areaCodes: null,
				dialCode: "65",
				iso2: "SG",
				name: "Singapore",
				priority: 0,
			},
			phoneMask: "########",
			countryDialCode: "+65",
			countryList: allCountries,
		};
	},
	components: {
		//SelectInput,
	},
	watch: {
		value(param1, param2) {
			if (param1 != param2) {
				this.setValues();
			}
		},
	},
	methods: {
		setValues() {
			const value = replace(this.value, "+", "");
			const phoneCode = split(value, "-");

			if (phoneCode.length != 2) {
				const countryCode = find(this.countryList, {
					dialCode: "65",
				});
				if (countryCode) {
					this.countryCode = countryCode;
					this.activeCountry = countryCode;
				}

				this.phone = null;
				return false;
			}
			if (phoneCode && phoneCode[0]) {
				const countryCode = find(this.countryList, {
					dialCode: phoneCode[0],
				});
				if (countryCode) {
					this.countryCode = countryCode;
					this.activeCountry = countryCode;
				}
			}
			if (phoneCode[0]) {
				if (phoneCode[0] == "65") {
					this.countryDialCode = `+${phoneCode[0]}`;
					//this.phoneMask = `########`;
				} else {
					this.countryDialCode = `+${phoneCode[0]}`;
					//this.phoneMask = `##########`;
				}
			}
			// this.$nextTick(() => {
			this.phone = phoneCode[1] ? phoneCode[1] : null;
			// });
		},
		updateActiveCountry(phoneNumber) {
			const { dialCode } = this.countryCode;
			if (dialCode) {
				if (dialCode == "65") {
					this.countryDialCode = `+${dialCode}`;
					this.phoneMask = `########`;
				} else {
					this.countryDialCode = `+${dialCode}`;
					this.phoneMask = `##########`;
				}
				console.log(this.phoneMask);
			}
			//this.phone = null;
			this.$nextTick(() => {
				this.activeCountry = this.countryCode;
				if (phoneNumber) {
					this.inputvalue = `${this.countryDialCode}-${phoneNumber}`;
					this.$emit("input", this.inputvalue);
				} else {
					this.$emit("input", null);
				}
			});
			//console.log(this.inputvalue);
			this.validatePhoneNumber();
		},
		emitValue() {
			this.inputvalue = null;
			if (this.phone) {
				this.inputvalue = `${this.countryDialCode}-${this.phone}`;
			} else {
				this.inputvalue = null;
			}
			this.$emit("input", this.inputvalue);
			this.$emit("change", this.inputvalue);
		},
		disableCharacter($event, value) {
			if (
				$event.keyCode === 45 ||
				$event.keyCode === 69 ||
				$event.keyCode === 101 ||
				$event.keyCode === 46
			) {
				$event.preventDefault();
			}
			const { dialCode } = this.countryCode;
			const val = value ? value.toString() : "";
			if (dialCode == "65") {
				if (val.length == 8) {
					$event.preventDefault();
				}
			} else {
				if (val.length == 10) {
					$event.preventDefault();
				}
			}
		},
		validatePhoneNumber() {
			const _this = this;
			_this.fieldNameError = null;
			/* For Remove validation start */
			const validate = true;
			if (validate) {
				return false;
			}
			/* For Remove validation end*/
			if (this.validationField && !this.validationField.url_type) {
				return false;
			}
			if (!_this.inputvalue) {
				return false;
			}
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				ValidateInputField(`validate/${this.validationField.url_type}/any-field`, {
					filter_type:
						_this.validationField && _this.validationField.filter_type
							? _this.validationField.filter_type
							: null,
					field:
						_this.validationField && _this.validationField.field ? _this.validationField.field : null,
					value: _this.inputvalue,
					parentId: this.parentId,
					id: this.currentId,
				})
					.then((output) => {
						if (output.exists) {
							_this.fieldNameError = `<b class='fw-600'>${_this.inputvalue}</b> already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
	},
	computed: {
		rules() {
			const { dialCode } = this.countryCode;
			/* const maxLimit = dialCode == "65" ? 8 : 10;
			const minLimit = dialCode == "65" ? 8 : 10; */
			const numberLimit = dialCode == "65" ? 8 : 10;
			/* const rule = [
				this.vrules.minLength(this.phone, "phone number", minLimit),
				this.vrules.maxLength(this.phone, "phone number", maxLimit),
			]; */
			const rule = [this.vrules.phoneNumber(this.phone, "phone number", numberLimit)];
			if (this.validationField && this.validationField.url_type) {
				rule.push(this.fieldNameError ? false : true);
			}
			if (this.required) {
				rule.push(this.vrules.required(this.phone, "phone number"));
			}
			return rule;
		},
		getPrefix() {
			return `${this.countryDialCode}-`;
		},
		getPhoneMask() {
			return this.phoneMask;
		},
	},
	mounted() {
		this.setValues();
	},
};
</script>
